
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

// components
import InvitationSummary from "@/components/molecules/InvitationSummary.molecules.vue";

// interfaces
import {
  IInvitationSummary,
  IInvoiceSummary,
  ITestSummary,
} from "@/interfaces/invitation";

// composables
import useDateFormatter from "@/composables/dateFormatter";

export default defineComponent({
  components: { InvitationSummary },
  setup() {
    let invitationSummaryData = ref({});
    let invoiceSummaryData = ref({});

    onMounted(() => {
      const route = useRoute();

      const queries = route.query;
      if (queries.tests) {
        if (Array.isArray(queries.tests)) {
          queries.tests = queries.tests.map((d) => JSON.parse(d as string));
        } else {
          queries.tests = [JSON.parse(queries.tests)];
        }
      }

      invitationSummaryData.value = {
        project_id: Number(queries.project_id),
        total_participants: Number(queries.total_participants),
        total_tests: Number(queries.total_tests),
        total_token: Number(queries.total_token),
        ended_at: queries.ended_at as string,
        tests: queries.tests as unknown as ITestSummary[],
      } as IInvitationSummary;

      invoiceSummaryData.value = {
        status: queries.status,
        invoice_number: queries.invoice_number,
        created_at: queries.created_at,
      } as IInvoiceSummary;
    });

    const { formatDateTime } = useDateFormatter();

    return {
      invitationSummaryData,
      invoiceSummaryData,
      formatDateTime,
    };
  },
});
